<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Editar {{ editedItem.symbol }}</span>
        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab key="info">
            Info
          </v-tab>
          <v-tab key="es">
            Español
          </v-tab>
          <v-tab key="en">
            Inglés
          </v-tab>
          <v-tab key="po">
            Portugués
          </v-tab>
          <v-tab key="texts">
            Textos
          </v-tab>
        </v-tabs>
      </v-card-title>

      <v-card flat>
        <v-card-text>
          <v-container>
            <v-tabs-items v-model="tab">
              <v-tab-item key="info">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.symbol"
                      label="Símbolo"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.omim"
                      label="OMIM"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.locus"
                      label="Locus"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      item-text="text"
                      item-value="value"
                      :items="gxGeneCategories"
                      v-model="editedItem.category"
                      label="Categoría"
                      solo
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.data_matrix_key"
                      label="Data Matrix Key"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.haplotype_builder_string"
                      label="Haplotipo"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="es">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.name_es"
                      label="Nombre ES"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.summary_es"
                      label="Resumen ES"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="en">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.name_en"
                      label="Nombre EN"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.summary_en"
                      label="Resumen EN"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="po">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.name_po"
                      label="Nombre PO"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.summary_po"
                      label="Resumen PO"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="es">
                <v-tabs v-model="textsTabs" fixed-tabs>
                  <v-tab key="gray" class="primary--text">
                    <v-icon color="grey">mdi-circle</v-icon>
                  </v-tab>
                  <v-tab key="green" class="primary--text">
                    <v-icon color="green">mdi-circle</v-icon>
                  </v-tab>
                  <v-tab key="yellow" class="primary--text">
                    <v-icon color="yellow">mdi-circle</v-icon>
                  </v-tab>
                  <v-tab key="orange" class="primary--text">
                    <v-icon color="orange">mdi-circle</v-icon>
                  </v-tab>
                  <v-tab key="red" class="primary--text">
                    <v-icon color="red">mdi-circle</v-icon>
                  </v-tab>

                  <v-tab-item key="gray">
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.gray.es.phenotype"
                          label="Fenotipo ES"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.gray.es.activity"
                          label="Actividad ES"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.gray.en.phenotype"
                          label="Fenotipo EN"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.gray.en.activity"
                          label="Actividad EN"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.gray.po.phenotype"
                          label="Fenotipo PO"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.gray.po.activity"
                          label="Actividad PO"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="green">
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.green.es.phenotype"
                          label="Fenotipo ES"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.green.es.activity"
                          label="Actividad ES"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.green.en.phenotype"
                          label="Fenotipo EN"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.green.en.activity"
                          label="Actividad EN"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.green.po.phenotype"
                          label="Fenotipo PO"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.green.po.activity"
                          label="Actividad PO"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="yellow">
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.yellow.es.phenotype"
                          label="Fenotipo ES"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.yellow.es.activity"
                          label="Actividad ES"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.yellow.en.phenotype"
                          label="Fenotipo EN"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.yellow.en.activity"
                          label="Actividad EN"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.yellow.po.phenotype"
                          label="Fenotipo PO"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.yellow.po.activity"
                          label="Actividad PO"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="orange">
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.orange.es.phenotype"
                          label="Fenotipo ES"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.orange.es.activity"
                          label="Actividad ES"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.orange.en.phenotype"
                          label="Fenotipo EN"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.orange.en.activity"
                          label="Actividad EN"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.orange.po.phenotype"
                          label="Fenotipo PO"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.orange.po.activity"
                          label="Actividad PO"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="red">
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.red.es.phenotype"
                          label="Fenotipo ES"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.red.es.activity"
                          label="Actividad ES"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.red.en.phenotype"
                          label="Fenotipo EN"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.red.en.activity"
                          label="Actividad EN"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model="editedItem.texts.red.po.phenotype"
                          label="Fenotipo PO"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="editedItem.texts.red.po.activity"
                          label="Actividad PO"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="save">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["visible", "item"],
  data: () => {
    return {
      tab: "info",
      textsTabs: "gray",
      editedItem: {},
      gxGeneCategories: [
        { text: "Transportador", value: "Transporter" },
        { text: "Pleiotrópico", value: "Pathogenic" },
        { text: "Receptor", value: "Receptor" },
        { text: "Metabolizador de fase I", value: "Phase I Metabolism" },
        { text: "Metabolizador de fase II", value: "Phase II Metabolism" }
      ],
      dialog: false
    };
  },
  mounted() {
    this.editedItem = this.item;
    this.dialog = this.visible;
  },
  watch: {
    item: {
      handler(val) {
        this.editedItem = val;
      },
      deep: true
    },
    visible: {
      handler(val) {
        this.dialog = val;
      }
    }
  },
  methods: {
    save() {
      this.$emit("save", this.editedItem);
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
